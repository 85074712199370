import { Button, Collapse, Input } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import Dragger from 'antd/lib/upload/Dragger';
import { useEffect, useState } from 'react';
import parser from 'subtitles-parser';
import chineseToPinyin from 'chinese-to-pinyin';
import hanvietData from './hanviet-1.json';

export const TransPingYing = () => {
    const [file, setFile] = useState(null);
    const [content, setContent] = useState('');
    const [subRes, setSubRes] = useState('');

    const propsFirst = {
        name: 'file',
        multiple: false,

        customRequest: (options) => {
            const { file } = options;
            setFile(file);
        },
    };

    const chineseToHanViet = (inputText) => {
        const hanVietData = [];
        new Array(inputText.length).fill(0).map((a, index) => {
            const chart = inputText.charAt(index);
            const data = hanvietData[chart];
            if (!data) {
                hanVietData.push('');
                return;
            }
            const trans = data.hHan.split(',')[0];
            hanVietData.push(trans);
        });
        return hanVietData;
    };

    const translateSub = async () => {
        if (!content) {
            return;
        }
        const subData = parser.fromSrt(content);

        const formatedSub = subData.map((item) => {
            const text = item.text;
            let trans = chineseToPinyin(text);
            if (text === trans) {
                trans = '';
            }
            const hanVietTrans = chineseToHanViet(text);
            return { ...item, text: `${text} \n ${trans} \n ${hanVietTrans.join(' ')}` };
        });
        const newSubData = parser.toSrt(formatedSub);
        saveFile(newSubData);
    };

    const saveFile = (content) => {
        let a = document.createElement('a');
        a.href = window.URL.createObjectURL(new Blob([content], { type: 'text/plain' }));
        a.download = 'demo.srt';
        a.click();
    };

    useEffect(() => {
        if (!file) {
            return;
        }
        getFileContent();
    }, [file]);

    const getFileContent = () => {
        if (!file) {
            return;
        }
        setContent('');

        setTimeout(() => {
            const reader1 = new FileReader();
            reader1.onload = function (e) {
                var content = reader1.result;

                setContent(content);
            };
            reader1.readAsText(file);
        }, 500);
    };

    return (
        <div className="w-1/2 mt-5">
            <Collapse defaultActiveKey={['1']}>
                <Collapse.Panel header="Dich sub" key="1">
                    <div className="flex gap-3 h-fit">
                        <div className="flex flex-col gap-5 w-full">
                            <div className="flex flex-col h-fit">
                                <Dragger {...propsFirst}>
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">Chọn file </p>
                                    <p className="ant-upload-hint">Chỉ nhận file srt</p>
                                </Dragger>
                            </div>

                            <div className="flex gap-5">
                                <Button className="mt-5 bg-blue-400 w-full" type="primary" onClick={translateSub}>
                                    Dịch
                                </Button>
                            </div>
                        </div>
                    </div>
                </Collapse.Panel>
            </Collapse>
        </div>
    );
};
