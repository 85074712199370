import { Button, Collapse, Input } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import Dragger from 'antd/lib/upload/Dragger';
import { useEffect, useState } from 'react';
import parser from 'subtitles-parser';
import TextArea from 'antd/lib/input/TextArea';

export const MergeSub = () => {
    const [file1, setFile1] = useState(null);
    const [file2, setFile2] = useState(null);
    const [content1, setContent1] = useState('');
    const [content2, setContent2] = useState('');
    const [file1StartIdx, setFile1StartIdx] = useState();
    const [file2StartIdx, setFile2StartIdx] = useState();
    const [tempRes, setTempRes] = useState('');
    const [subRes, setSubRes] = useState('');

    const propsFirst = {
        name: 'file',
        multiple: false,

        customRequest: (options) => {
            const { file } = options;
            setFile1(file);
        },
    };
    const propsSec = {
        name: 'file',
        multiple: false,

        customRequest: (options) => {
            const { file } = options;
            setFile2(file);
        },
    };

    const saveFile = (content) => {
        let a = document.createElement('a');
        a.href = window.URL.createObjectURL(new Blob([content], { type: 'text/plain' }));
        a.download = 'demo.srt';
        a.click();
    };

    const doMerge = () => {
        let subData = parser.fromSrt(content1);
        let subData2 = parser.fromSrt(content2);

        if (file1StartIdx) {
            const idx = parseInt(file1StartIdx) || 0;
            subData = subData.slice(idx - 1, subData.length);
        }

        if (file2StartIdx) {
            const idx = parseInt(file2StartIdx) || 0;
            subData2 = subData2.slice(idx - 1, subData2.length);
        }

        const formatedSub = subData.map((item, index) => {
            const text = item.text;
            const trans = subData2[index] || { text: '' };
            return { ...item, text: `${text} \n ${trans.text}` };
        });
        const newSubData = parser.toSrt(formatedSub);
        setTempRes(parser.toSrt([...formatedSub].slice(0, 20)));
        setSubRes(newSubData);

        setContent1('');
        setContent2('');
    };

    useEffect(() => {
        if (!content1 || !content2) {
            return;
        }
        doMerge();
    }, [content1, content2]);

    const merge = () => {
        if (!file1 || !file2) {
            return;
        }
        setContent1('');
        setContent2('');

        setTimeout(() => {
            const reader1 = new FileReader();
            reader1.onload = function (e) {
                var content = reader1.result;

                setContent1(content);
            };
            reader1.readAsText(file1);

            const reader2 = new FileReader();
            reader2.onload = function (e) {
                var content = reader2.result;

                setContent2(content);
            };
            reader2.readAsText(file2);
        }, 500);
    };

    return (
        <div className="w-full mt-5">
            <Collapse defaultActiveKey={['1']}>
                <Collapse.Panel header="Gộp sub" key="1">
                    <div className="flex gap-3 h-fit">
                        <div className="flex flex-col gap-5 w-1/2">
                            <div className="flex flex-col h-fit">
                                <Input
                                    value={file1StartIdx}
                                    onChange={(e) => setFile1StartIdx(e.target.value)}
                                    placeholder="Vị trí bắt đầu file 1"
                                />
                                <Dragger {...propsFirst}>
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">Chọn file 1</p>
                                    <p className="ant-upload-hint">Chỉ nhận file srt</p>
                                </Dragger>
                            </div>

                            <div className="flex flex-col">
                                <Input
                                    value={file2StartIdx}
                                    onChange={(e) => setFile2StartIdx(e.target.value)}
                                    placeholder="Vị trí bắt đầu file 2"
                                />
                                <Dragger {...propsSec}>
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">Chọn file 2</p>
                                    <p className="ant-upload-hint">Chỉ nhận file srt</p>
                                </Dragger>
                            </div>
                            <div className="flex gap-5">
                                <Button className="mt-5 bg-blue-400 w-full" type="primary" onClick={merge}>
                                    Hợp nhất
                                </Button>
                                <Button className="mt-5 bg-blue-400 w-full" type="dashed" onClick={() => saveFile(subRes)}>
                                    Tải về
                                </Button>
                            </div>
                        </div>
                        <div className="w-1/2">
                            <TextArea className="w-full !h-full" value={tempRes} disabled />
                        </div>
                    </div>
                </Collapse.Panel>
            </Collapse>
        </div>
    );
};
