import logo from './logo.svg';
import 'antd/dist/antd.css';
import './App.css';
import { Button, Input } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import parser from 'subtitles-parser';
import chineseToPinyin from 'chinese-to-pinyin';
import hanvietData from './hanviet-1.json';
import { MergeSub } from './MergeSub';
import { TransPingYing } from './transSubToPingYing';

function App() {
    const [inputText, setInputText] = useState('');
    const [outputData, setOutputData] = useState([]);
    const [hoverIndex, setHoverIndex] = useState(0);
    const [hanvietRes, setHanVietRes] = useState([]);

    const outputText = useMemo(() => {
        return outputData.join(' ');
    }, [outputData]);

    const translate = async () => {
        const url = `https://helloacm.com/api/pinyin/?cached&s=${inputText}`;
        const res = await axios.get(url);
        console.log(res);
        const { data } = res;
        if (data) {
            const { result } = data;
            setOutputData(result);
        }
        const resHanViet = chineseToHanViet(inputText);
        setHanVietRes(resHanViet);
    };

    const saveFile = (content) => {
        let a = document.createElement('a');
        a.href = window.URL.createObjectURL(new Blob([content], { type: 'text/plain' }));
        a.download = 'demo.srt';
        a.click();
    };

    const chineseToHanViet = (inputText) => {
        const hanVietData = [];
        new Array(inputText.length).fill(0).map((a, index) => {
            const chart = inputText.charAt(index);
            const data = hanvietData[chart];
            if (!data) {
                hanVietData.push(chart);
                return;
            }
            const trans = data.hHan.split(',')[0];
            hanVietData.push(trans);
        });
        return hanVietData;
    };

    const translateSub = async () => {
        const subData = parser.fromSrt(inputText);
        console.log(subData);
        const formatedSub = subData.map((item) => {
            const text = item.text;
            const trans = chineseToPinyin(text);
            const hanVietTrans = chineseToHanViet(text);
            return { ...item, text: `${text} \n ${trans} \n ${hanVietTrans.join(' ')}` };
        });
        const newSubData = parser.toSrt(formatedSub);
        saveFile(newSubData);
        console.log(newSubData);
    };

    const SupportReading = () => {
        if (!outputData || outputData.length === 0) {
            return;
        }
        console.log(hoverIndex);
        return (
            <div className="flex flex-col gap-3 bg-slate-100 rounded-sm p-2 w-full">
                <div className="flex gap-0.5">
                    {new Array(inputText.length).fill(0).map((a, index) => (
                        <span
                            onMouseEnter={(e) => setHoverIndex(index)}
                            className={hoverIndex === index ? 'bg-blue-100 font-bold' : 'bg-transparent'}
                        >
                            {inputText.charAt(index)}
                        </span>
                    ))}
                </div>
                <div className="flex gap-0.5">
                    {outputData.map((char, index) => (
                        <span
                            onMouseEnter={(e) => setHoverIndex(index)}
                            className={hoverIndex === index ? 'bg-blue-100 font-bold' : 'bg-transparent'}
                        >
                            {char}
                        </span>
                    ))}
                </div>
                <div className="flex gap-0.5">
                    {hanvietRes.map((char, index) => (
                        <span
                            onMouseEnter={(e) => setHoverIndex(index)}
                            className={hoverIndex === index ? 'bg-blue-100 font-bold' : 'bg-transparent'}
                        >
                            {char}
                        </span>
                    ))}
                </div>
            </div>
        );
    };

    return (
        <div className="App">
            <div className="flex gap-2 p-2">
                <div className="flex-1 flex flex-col gap-2 items-start w-1/2">
                    <span>Bản gốc</span>
                    <Input.TextArea value={inputText} onChange={(e) => setInputText(e.target.value)} />
                    <div className="flex gap-2">
                        <Button type="primary" className="bg-blue-500" onClick={translate}>
                            Dịch
                        </Button>
                        <Button type="primary" className="bg-blue-500" onClick={translateSub}>
                            Dịch sub
                        </Button>
                    </div>
                </div>
                <div className="flex-1 flex flex-col gap-2 items-start  w-1/2">
                    <span>Bản dịch</span>
                    {/* <Input.TextArea value={outputText} /> */}
                    {SupportReading()}
                </div>
            </div>

            <TransPingYing />
            <MergeSub />
        </div>
    );
}

export default App;
